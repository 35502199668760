import type { I18n } from '@lingui/core';
import { ko, zh, en, ja } from 'make-plural/plurals';

export function initTranslation(i18n: I18n): void {
  i18n.loadLocaleData({
    en: { plurals: en },
    zh: { plurals: zh },
    ['zh-tw']: { plurals: zh },
    ko: { plurals: ko },
    ja: { plurals: ja },
    pseudo: { plurals: zh },
  });
}
