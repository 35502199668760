import '@/styles/globals.less';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/css/effect-fade';
import { useRef } from 'react';
import Head from 'components/Head';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import React, { createContext, useEffect } from 'react';
import { GlobalContext } from './_app.d';
import { i18n } from '@lingui/core';
import { initTranslation } from '@/libs';
import { I18nProvider } from '@lingui/react';
import Cookies from 'js-cookie';

initTranslation(i18n);

export const GlobalData = createContext<GlobalContext>({
  routerConfig: [],
});

export function reportWebVitals(metric: NextWebVitalsMetric) {
  // 开发环境输出ttfb时间
  if (metric.name === 'TTFB') {
    if (process.env.APP_ENV !== 'prod') {
      console.log('TTFB:', Math.ceil(metric.value));
    }
  }
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  const firstRender = useRef(true);

  const locale = router.locale || router.defaultLocale;

  if (pageProps.translation && firstRender.current) {
    if (locale) {
      i18n.load(locale, pageProps.translation);
      i18n.activate(locale);
      firstRender.current = false;
    }
  }

  useEffect(() => {
    if (pageProps.translation && locale) {
      i18n.load(locale, pageProps.translation);
      i18n.activate(locale);
      // 持久化语言
      Cookies.set('lang', locale, { expires: 30 });
    }
  }, [locale, pageProps.translation]);

  return (
    <I18nProvider i18n={i18n}>
      <Head />
      <Component {...pageProps} />
    </I18nProvider>
  );
};

export default MyApp;
