import Cookies from 'js-cookie';
import config from '@/config/config';

const tk = config.tk;

export function getToken() {
  return Cookies.get(tk);
}

export function setToken(token: string) {
  return Cookies.set(tk, token);
}

export function removeToken() {
  Cookies.remove(tk);
}
