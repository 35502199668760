import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN;
const release = process.env.SENTRY_RELEASE;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://626776c8f5347b835def5f8cfe6f1ed5@sentry.manjuu.com/4',
  tracesSampleRate: 1.0,
  environment: process.env.APP_ENV,
  release,
});
// export {};
