export async function loadTranslation(locale: string, isProduction = true) {
  let data;
  if (isProduction) {
    let convertLocaleKeywords = locale;
    if (locale === 'jp') {
      convertLocaleKeywords = 'ja-jp';
    }
    if (locale === 'kr') {
      convertLocaleKeywords = 'ko-kr';
    }
    // 生产环境是js文件
    data = await import(
      `../translations/locales/${convertLocaleKeywords}/messages`
    );
  } else {
    // 开发环境是po文件
    let convertLocaleKeywords = locale;
    if (locale === 'jp') {
      convertLocaleKeywords = 'ja-jp';
    }
    if (locale === 'kr') {
      convertLocaleKeywords = 'ko-kr';
    }
    data = await import(
      `@lingui/loader!../translations/locales/${convertLocaleKeywords}/messages.po`
    );
  }

  return data.messages;
}
