module.exports = {
  local: [
    {
      source: '/api/:path*',
      destination: 'http://192.168.40.61/:path*',
    },
  ],
  dev: [
    {
      source: '/api/:path*',
      destination: 'http://192.168.40.61/:path*',
    },
  ],
  qa: [
    {
      source: '/api/:path*',
      destination: 'http://192.168.200.235:8081/:path*',
    },
  ],
  ce: [
    {
      source: '/api/:path*',
      destination: 'http://192.168.200.235:8081/:path*',
    },
  ],
  prod: [
    {
      source: '/api/:path*',
      destination: 'http://192.168.40.61/:path*',
    },
  ],
};
