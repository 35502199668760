import Router from 'next/router';

export const parseQuery: (search: string) => Record<string, any> = search => {
  if (search) {
    try {
      const handleData: string =
        search.indexOf('?') === 0 ? search.slice(1, search.length) : search;
      const result: Record<string, any> = {};
      for (const item of handleData?.split('&')) {
        const key: string = item.substring(0, item.indexOf('='));
        result[key] = item.substring(item.indexOf('=') + 1, item.length);
      }
      return result;
    } catch (e) {
      console.log('parseQuery ERROR:', e);
    }
  }
  return {};
};

// 驼峰命名转下划线
export const toLine: (name: string) => string = name =>
  name.replace(/([A-Z])/g, '_$1').toLowerCase();

// 下划线转驼峰命名
export const toHump: (name: string) => string = name =>
  name.replace(/_(\w)/g, (all, letter) => letter.toUpperCase());

export const goToRedirect = (other_redirect?: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const queryObj = parseQuery(location.href.split('?')[1]);
    if (queryObj?.redirect) {
      Router.push(queryObj?.redirect);
      resolve(queryObj?.redirect);
    }
    reject(other_redirect);
  });
};

// 校验身份证号码
export const validateIDCard = (idNumber: string): boolean => {
  if (typeof idNumber !== 'string') {
    throw new Error(
      'The input value should be "string" in function of validateIDCard'
    );
  }

  const format =
    /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
  // 号码规则校验
  if (!format.test(idNumber)) {
    return false;
  }
  // 出生年月日校验   前正则限制起始年份为1900;
  const year = idNumber.substring(6, 10); // 身份证年
  const month = idNumber.substring(10, 12); // 身份证月
  const date = idNumber.substring(12, 14); // 身份证日
  const time = Date.parse(month + '-' + date + '-' + year); // 身份证日期时间戳date
  const now_time = Date.now(); // 当前时间戳
  const dates = new Date(Number(year), Number(month), 0).getDate(); // 身份证当月天数
  if (time > now_time || Number(date) > Number(dates)) {
    return false;
  }
  // 校验码判断
  const c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 系数
  const b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对照表
  const id_array = idNumber.split('');
  let sum = 0;
  for (var k = 0; k < 17; k++) {
    sum += parseInt(id_array[k]) * parseInt(String(c[k]));
  }
  return id_array[17].toUpperCase() === b[sum % 11].toUpperCase();
};

export const clickBaiduEvent = (callback: (push: Function) => void) => {
  try {
    if (process.env.APP_ENV === 'prod') {
      if (window && window?._hmt && window?._hmt?.push) {
        callback(window?._hmt?.push);
      }
    }
  } catch (err) {
    console.log(err);
  }
};
