import { sign as signHandle } from '@/libs';
import { GlobalType } from '@/types/global';
import Router from 'next/router';
export interface OProps {
  method: string;
  data?: any;
}

interface Request {
  <R = any>(url: string, options: OProps): Promise<R>;
}

export const ErrorCode = {
  1000: '成功',
  1001: '系统错误',
  1002: 'IP被禁止',
  1003: '服务器维护中',
  1004: '签名错误',
  1005: '参数错误',
  1006: '验证码错误',
  1007: '验证码已过期',
  1008: '验证码刷新过于频繁',
  1009: '非游客账号',
  1010: '注册的账号已经存在',
  1011: '用户账号不存在',
  1012: '密码错误',
  1013: '用户账号或密码错误',
  1014: '用户已被禁用',
  1015: 'session已失效',
  1017: '手机未绑定账号',
  1018: '手机已绑定账号',
  1022: '邮箱未绑定账号',
  1023: '邮箱已绑定账号',
  1035: '人机验证',
  1037: 'cookie验证失败',
  9999: '',
};

const request: Request = (reqUrl: string, options: OProps) => {
  const { method, data = {} } = options;
  let url = reqUrl;

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.APP_ENV === 'local'
  ) {
    if (!(reqUrl.startsWith('http') || reqUrl.startsWith('https'))) {
      if (reqUrl.startsWith('/')) {
        url = `/api${reqUrl}`;
      } else {
        url = `/api/${reqUrl}`;
      }
    }
  }

  // 获取本地化
  const lang = (Router.locale || Router.defaultLocale) as string;
  let requestConfig: any = {
    method: method.toUpperCase(),
    withCredentials: true,
  };
  if (method?.toUpperCase() === 'GET') {
    const dataStr = Object.keys(data).reduce((previousValue, currentValue) => {
      return previousValue + `${currentValue}=${data[currentValue]}&`;
    }, '');

    if (dataStr) {
      const resultUrl = `${url}?${dataStr.substring(
        0,
        dataStr.lastIndexOf('&')
      )}`;
      url = resultUrl;
    }
  }

  if (method?.toUpperCase() === 'POST') {
    if (options) {
      const serializeData: Record<string, any> = {};

      /**
       * 由于最后是以FormData形式与服务器进行数据交互，所以会对引用类型进行序列化。
       *
       * 注：由于采取JSON.stringify, 所以须注意对象循环引用报错问题
       * */
      for (const key in data) {
        if (
          (typeof data[key]).toLowerCase() === 'object' &&
          data[key] !== null
        ) {
          serializeData[key] = JSON.stringify(data[key]);
        } else {
          serializeData[key] = data[key];
        }
      }
      // 签名 返回 随机数 和 签名
      const { datetime, sign } = signHandle(url, {
        method,
        data: {
          ...serializeData,
          lang,
        },
      });
      const formData = new FormData();
      const value: Record<string, string> = {
        // 业务数据
        ...serializeData,
        sign,
        lang,
        datetime: String(datetime),
      };
      for (const key in value) {
        formData.append(key, value[key]);
      }
      Object.defineProperty(requestConfig, 'body', { value: formData });
    }
  }
  return new Promise(async (resolve, reject) => {
    try {
      // 请求超时设置
      const TIME_OUT_ID = setTimeout(() => {
        reject('请求超时');
        clearTimeout(TIME_OUT_ID);
      }, 5000);

      const response = await fetch(url, requestConfig);
      clearTimeout(TIME_OUT_ID);
      const resJson: GlobalType.Result = await response.json();

      if (resJson.code > 1000) {
        // 失败
        console.error(ErrorCode[resJson.code as keyof typeof ErrorCode]);
        reject(resJson);
      }

      resolve(resJson as any);
    } catch (err) {
      reject(err);
      console.log(err);
    }
  });
};

export { request };
