/**
 * @author Zilong Wang <wangzi6224@gmail.com>
 * @date 2022/7/22 18:54
 * @Last Modified by: Zilong Wang <wangzi6224@gmail.com>
 * @Last Modified time: 2022/7/22 18:54
 */

import md5 from 'js-md5';
import config from '@/config/config';
import { parseQuery, OProps } from '@/libs';

type signType = (
  url: string,
  options?: OProps,
  testRandom?: number
) => {
  sign: string;
  datetime: number;
};

/**
 * 签名机制
 * 对所有url参数，除去sign，
 * 按ascii码从小到大排序好后（先比较首字母，首字母相同的比较第二个字母，以此类推）用&连接起来，最后加上&private_key=私钥(4ff22a35c78df1577480cb53dd785590)，
 * 然后对这个字符串进行MD5加密，填入sign参数的值中
 * sign值计算示例（获取验证码接口）：郵箱
 * md5(datetime=1626837588&get_type=2&action=GetCaptcha&lang=en&receiver=15866886688&use_type=1&private_key=blabla)
 * 【注意】
 * 账号相关的协议签名密钥是：全局config对象下的spk字段；
 * */
export const sign: signType = (url, options, testRandom) => {
  const PrivateKey = config.spk;
  const datetime = testRandom || Date.now() * Math.random();

  if (options) {
    const method = options.method.toLowerCase();
    const data = options.data || {};

    let params: Record<string, any> = {};

    if (method === 'get') {
      params = { ...(parseQuery(url.split('?')[1]) || {}), datetime };
    }

    if (method === 'post') {
      params = { ...data, datetime };
    }

    // 去掉undefined
    const filterData = JSON.parse(JSON.stringify(params));

    // 将key排序
    const sortByAscii = Object.keys(filterData).sort();

    // 重新拼串
    const result = sortByAscii.reduce((previousValue, currentValue) => {
      if (typeof filterData[currentValue] === 'object') {
        return (
          previousValue +
          `${currentValue}=${JSON.stringify(filterData[currentValue])}&`
        );
      }
      return previousValue + `${currentValue}=${filterData[currentValue]}&`;
    }, '');

    const private_key = `private_key=${PrivateKey}`;

    // if (testRandom) {
    //   return md5(
    //     `${result.substring(0, result.lastIndexOf('&'))}&${private_key}`
    //   );
    // }

    /**
     * format:
     * sign = data + private_key
     * */
    return {
      sign: md5(
        `${result.substring(0, result.lastIndexOf('&'))}&${private_key}`
      ),
      datetime,
    };
  }

  return {
    sign: md5(PrivateKey + Date.now() * Math.random()),
    datetime,
  };
};
