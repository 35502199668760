import React from 'react';
import Head from 'next/head';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';

const config = require('@/config/config.js');

const PageHead: React.FC = () => {
  const { isLang } = useIsInternational();

  return (
    <Head>
      {isLang('zh') && (
        <>
          <meta
            name="keywords"
            content="黄鸡、小黄鸡、碧蓝、碧蓝航线、即时海战、Azur Lane、蛮啾、蛮啾网络、蛮啾官网、蓝原、星原、旅谣、蓝色星原旅谣、蓝色星原、蛮啾游戏、蛮啾手游、黄鸡游戏、黄鸡手游、蓝原、AL、蛮秋、manjuu、manjuugames、MANJUU、MANJUUGAMES"
          />
          <meta
            name="description"
            content="是一家由一群年轻的ACG爱好者所组成的新兴游戏公司。我们的共同目标是：做最有趣的二次元游戏。"></meta>
        </>
      )}
      <meta
        name="viewport"
        content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width"
      />
      <meta
        name="theme-color"
        media="(prefers-color-scheme: light)"
        content={config.ThemeColor}></meta>
      {process.env.APP_ENV === 'prod' && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                    var _hmt = _hmt || [];
                    (function() {
                      var hm = document.createElement("script");
                      hm.src = "https://hm.baidu.com/hm.js?605f96f2a6f4ab824b6f4215f2825d3e";
                      var s = document.getElementsByTagName("script")[0]; 
                      s.parentNode.insertBefore(hm, s);
                    })();
                  `,
          }}
        />
      )}
      <title>{t`蛮啾`}</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export default PageHead;
