import md5 from 'js-md5';

export type CaptchaSign = (
  challenge: string,
  testRandStr?: string
) => {
  challengeRand: string;
  challenge: string;
};

const captchaSign: CaptchaSign = (challenge, testRandStr) => {
  let challengeRand: string;
  if (testRandStr && process.env.NODE_ENV === 'test') {
    // 测试随机数
    challengeRand = testRandStr;
  } else {
    challengeRand = Math.floor(Date.now() * Math.random()).toString();
  }
  const breakPoint: number = Number(challengeRand[challengeRand.length - 1]);
  const startChallenge: string = challenge.substring(0, breakPoint);
  const endChallenge: string = challenge.substring(breakPoint);

  return {
    challengeRand,
    challenge: md5(`${startChallenge}${challengeRand}${endChallenge}`),
  };
};

export { captchaSign };
