import { useRouter } from 'next/router';
import { GlobalType } from '@/types/global';

export const useIsInternational = (): {
  isInternational: boolean;
  isLang: (lang: GlobalType.LangType) => boolean;
  lang: GlobalType.LangType;
} => {
  const { locale } = useRouter();
  const isLang = (lang: GlobalType.LangType): boolean => {
    return locale === lang;
  };

  return {
    isInternational: locale !== 'zh',
    isLang,
    lang: locale as GlobalType.LangType,
  };
};
